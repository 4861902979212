import { Box } from '@mui/material'
import React from 'react'

export default function SadarMajkur() {
  return (
    <Box sx={{mt:'-2'}}>
      <p style={{color:'#4F62B0'}} >Content Visible To</p>
    </Box>
  )
}
