import React from 'react'
import '../CSS/Footer.css'

export default function Footer() {
  return (
    <div className='footerClass'>
        <h5 className='footerText'>2022-23. All Rights Reserved by Labindia analytical Instruments PVT. LTD./ Wayam Magazine</h5>
    </div>
  )
}
